import { FETCH_DB_OPTIMISER_TASKS_API, FETCH_DB_OPTIMISER_TASKS_API_SUCCESS  ,FETCH_DB_OPTIMISER_TASKS_API_FAILED } from "../actions";

export const fetchOptimiserLists = (user) => ({
    type: FETCH_DB_OPTIMISER_TASKS_API,
    payload: { user }
})


export const fetchConnectionSuccess = (results) => ({
  type: FETCH_DB_OPTIMISER_TASKS_API_SUCCESS,
  payload: results
})

export const fetchOptimiserListsError = (results) => ({
  type: FETCH_DB_OPTIMISER_TASKS_API_FAILED,
  payload: results
})