import axios from "axios";

export const postURL = async (url, payload) => {
  let config = {
    method: "post",
    url,
    data: payload,
  };
  return await new Promise((resolve, reject) => {
    axios(config)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getURL = async (url) => {
  let config = { method: "get", url };
  return await new Promise((resolve, reject) => {
    axios(config)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const putURL = async (url, payload, contentType='') => {
  let config = {
    method: "put",
    url,
    data: payload,
  };

  if(contentType) {
    config.headers = {
      'Content-Type': contentType,
    }
  }
  return await new Promise((resolve, reject) => {
    axios(config)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteUrl = async (url) => {
  let config = {
    method: "delete",
    url
  };
  return await new Promise((resolve, reject) => {
    axios(config)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
