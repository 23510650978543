import { API_FAILED, FETCH_CONNECTION_API, FETCH_DB_OPTIMISER_TASKS_API } from "../actions"

import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { fetchConnectionSuccess } from "./actions"
import { loadOptimiserList } from "./api"

export function* watchConnectionAPI() {
  yield takeEvery(FETCH_DB_OPTIMISER_TASKS_API, getOptimiserTasks)
}



function* getOptimiserTasks({ payload }) {
  try {
    const result = yield call(loadOptimiserList)
    console.log("KK", result);
    if (result?.status === 200) {
      yield put(fetchConnectionSuccess(result.data))
    } else {
      yield put(API_FAILED(result.statusText))
    }


  } catch (error) {
    yield put(API_FAILED(error))

  }
}


export default function* rootSaga() {
  yield all([
    fork(watchConnectionAPI),
  ])
}

