const initial = {
    showLoadingSpinner: false,
    message: ''
}

export const showLoadingSpinner = (data) => {
    return {
        type: 'SHOW_LOADING_SPINNER',
        payload: data
    }
};

export const hideLoadingSpinner = () => {
    return {
        type: 'HIDE_LOADING_SPINNER',
        payload: {}
    }
}

const globalLoadingSpinner = (state = initial, action) => {
    switch (action.type) {
        case 'SHOW_LOADING_SPINNER':
            return {
                showLoadingSpinner: true,
                message: action.payload
            }
        case 'HIDE_LOADING_SPINNER':
            return {
                showLoadingSpinner: false,
                message: ''
            }
        default: return { ...state }
    }
}

export default globalLoadingSpinner
