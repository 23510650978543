import { FETCH_DB_OPTIMISER_TASKS_API, FETCH_DB_OPTIMISER_TASKS_API_FAILED, FETCH_DB_OPTIMISER_TASKS_API_SUCCESS } from "../actions"


const INIT_STATE = {
    user: localStorage.getItem('user_id'),
    tasks: [],
    fetchingData: false,
    failedMsg: ""
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case  FETCH_DB_OPTIMISER_TASKS_API:
            return { ...state, fetchingData: true, failedMsg: '' }

        case FETCH_DB_OPTIMISER_TASKS_API_SUCCESS:
            return { ...state, tasks: action.payload, fetchingData: false, failedMsg: '' }
        case FETCH_DB_OPTIMISER_TASKS_API_FAILED:
            return { ...state, tasks: [], fetchingData: false, failedMsg: action.payload }

        default: return { ...state }
    }
}
