import { FETCH_APP_CONFIG, FETCH_APP_CONFIG_SUCCESS, FETCH_APP_CONFIG_FAILED } from "../actions";

export const fetchAppConfig = (user) => ({
  type: FETCH_APP_CONFIG,
  payload: { user }
})


export const fetchAppConfigSuccess = (results) => ({
  type: FETCH_APP_CONFIG_SUCCESS,
  payload: results
})

export const failedRequest = (results) => ({
  type: FETCH_APP_CONFIG_FAILED,
  payload: results
})