import { applyMiddleware, createStore, combineReducers } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import thunk from 'redux-thunk'
import reducers from './reducers'
import sagas from './sagas'
import { onCloseNotificationMiddleware } from './navbar'
import { removeProcessNotificationOnFirebase } from './navbar/processNotification'
import { persistStore, persistReducer } from 'redux-persist'
import scheduleStore from './scheduleStore'
import storage from 'redux-persist/lib/storage'

const sagaMiddleware = createSagaMiddleware()

const middleware = applyMiddleware(
  sagaMiddleware,
  thunk,
  onCloseNotificationMiddleware,
  removeProcessNotificationOnFirebase
)

const middlewares = window.__REDUX_DEVTOOLS_EXTENSION__ && process.env.NODE_ENV === 'development'
  ? composeWithDevTools(middleware)
  : middleware

export const persistStoreData = (store) => persistStore(store);

export function configureStore(initialState) {

  const store = createStore(
    reducers,
    initialState,
    middlewares
  )
  persistStoreData(store)
  sagaMiddleware.run(sagas)

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const nextRootReducer = require('./reducers')
      store.replaceReducer(nextRootReducer)
    })
  }

  return store
}
