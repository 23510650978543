import { API_FAILED, FETCH_APP_CONFIG } from "../actions"

import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { fetchAppConfigSuccess } from "./actions"
import { apiConfig } from "./api"

export function* watchConnectionAPI() {
  yield takeEvery(FETCH_APP_CONFIG, getAppConfig)
}



function* getAppConfig({ payload }) {
  try {
    const result = yield call(apiConfig)
    if (result?.status === 200) {
      yield put(fetchAppConfigSuccess(result.data[0]))
    } else {
      yield put(API_FAILED(result.statusText))
    }


  } catch (error) {
    yield put(API_FAILED(error))

  }
}


export default function* rootSaga() {
  yield all([
    fork(watchConnectionAPI),
  ])
}

