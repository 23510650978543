import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  USER_DETAILS,
  USER_DETAILS_FETCHING,
  LOGIN,
  LOGIN_SUCCESS,
  USER_LOGIN_FAILED,
  USER_LOGIN,
  USER_LOGIN_SUCCESS
} from '../actions'

const INIT_STATE = {
  user: localStorage.getItem('user_id'),
  forgotUserMail: '',
  newPassword: '',
  resetPasswordCode: '',
  loading: false,
  error: '',
  userData: {},
  userDetailFetched: false,
  userDetailFetching: false,
  userPermissions: {},
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case USER_LOGIN:
      return { ...state, loginInProgress: true }
    case USER_LOGIN_SUCCESS:
      action.payload.history.push("/app/ddlconverter")
      return {
        ...state, loginInProgress: false,
        loginUser: action.payload?.email,
        loginSuccess: true
      }
    case USER_LOGIN_FAILED:
    
      return {
        ...state,
        loginInProgress: false,
        loginSuccess: false
      }
    default: return { ...state }

  }
}
