import { API_FAILED, FETCH_CONNECTION_API, FETCH_DELETE_CONNECTION as DELETE_CONNECTION_API, FETCH_CONNECTION_DETAILS } from "../actions"

import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { fetchConnectionSuccess, fetchConnection, getConnectionsSuccess } from "./actions"
import { deleteConnection, loadConnection, getConnection as getConnectionAPI} from "./api"

export function* watchConnectionAPI() {
  yield takeEvery(FETCH_CONNECTION_API, getConnectionDetails)
}

export function* watchConnectionDeleteAPI() {
  yield takeEvery(DELETE_CONNECTION_API, deleteConnectionDetails)
}

export function* watchConnectionDetailAPI() {
  yield takeEvery(FETCH_CONNECTION_DETAILS, getConnection)
}


function* getConnectionDetails({ payload }) {
  try {
    const result = yield call(loadConnection)
    if (result?.status === 200) {
      yield put(fetchConnectionSuccess(result.data))
    } else {
      yield put(API_FAILED(result.statusText))
    }


  } catch (error) {
    yield put(API_FAILED(error))

  }
}

function* deleteConnectionDetails({ payload }) {
  try {
    console.log("Culprits");
    const result = yield call(deleteConnection, payload.id)
    if (result?.status === 200) {
      yield put(fetchConnection())
    } else {
      yield put(API_FAILED(result.statusText))
    }


  } catch (error) {
    yield put(API_FAILED(error))

  }
}


function* getConnection({ payload }) {
  try {
    const result = yield call(getConnectionAPI, payload.id)
    if (result?.status === 200) {
      yield put(getConnectionsSuccess(result.data))
    } else {
      yield put(API_FAILED(result.statusText))
    }


  } catch (error) {
    yield put(API_FAILED(error))

  }
}


export default function* rootSaga() {
  yield all([
    fork(watchConnectionAPI),
    fork(watchConnectionDeleteAPI),
    fork(watchConnectionDetailAPI)
  ])
}

