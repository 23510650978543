
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import {
  USER_LOGIN
} from '../actions'

import {
  loginFailed, loginSuccess,
} from './actions'
import { loginAPI } from './api'
import { Notification } from '../../helpers/appUtils';
import { NotificationManager } from '../../components/common/react-notifications';



function* login({ payload }) {
  try {
    const { data, history } = payload;

    const details = yield call(loginAPI, data);

    if (details?.data?.success) {
      localStorage.setItem("IsLoggedIn", true);
      yield put(loginSuccess({ history }))
      window.location.reload()
    } else {
      Notification.error('Could not able to login!\n please check your credentials \n or contact the admin')

      yield put(loginFailed())
    }
  } catch (exp) {
    NotificationManager.error('Could not able to login!\n please check your credentials \n or contact the admin')
    yield put(loginFailed())
  }


}


export function* watchLoginAPI() {
  yield takeEvery(USER_LOGIN, login);

}
export default function* rootSaga() {
  yield all([
    fork(watchLoginAPI)
  ])
}
