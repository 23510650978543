import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import * as R from 'ramda'

const initial = {
  notifications: []
}

export const onCloseNotificationMiddleware = ({ dispatch }) => next => action => {
  if (R.equals(action.type, 'removeNavNotification') && action.payload?.dispatchOnClose || R.equals(action.type, 'removeAllNavNotification')) {
    dispatch({ type: action.payload?.dispatchOnClose, payload: action.payload?.id })
  }

  return next(action)
}

const navbarReducer = (state = initial, action) => {
  switch (action.type) {
    case 'addNavNotification': {
      return {
        ...state, notifications: [...state.notifications, { ...action.payload, id: uuidv4() }]
      }
    }
    case 'removeAllNavNotification': {
      return {
        ...state, notifications: []
      }
    }
    case 'removeNavNotification': {
      return {
        ...state, notifications: state.notifications.filter(({ id }) => id !== action.payload?.id)
      }
    }
    default: return state
  }
}

export default navbarReducer

export const useNavNotifications = () => useSelector(state => state.navbar?.notifications)
