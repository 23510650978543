// API BASE URL
export const API_BASE_URL = process.env.REACT_APP_API_URL

// DDL CONVERTER
export const FILE_UPLOAD_URL = `${API_BASE_URL}/file/upload`

export const CONVERT_TRIGGER = `${API_BASE_URL}/modules/DDL Converter/{sub-module}/convert`

export const DOWNLOAD_TRIGGER = `${API_BASE_URL}/file/download`

// ADD CONNECTION
export const ADD_CONNECTION = `${API_BASE_URL}/connections`

// Delete Connection
export const DELETE_CONNECTION = `${API_BASE_URL}/connections/{connectionId}`

// GET CONNECTION
export const GET_CONNECTION = `${API_BASE_URL}/connections/{connectionId}`

export const LOGIN_API = `${API_BASE_URL}/auth/signin`

export const DDL_CONVERTER_CONVERT = `${API_BASE_URL}/modules/DDL Converter/{sub-module}/convert`

export const ETL_TRIGGER = `${API_BASE_URL}/modules/ETL Converter/convert`

export const ETL_ANALYSER = `${API_BASE_URL}/modules/ETL Converter/analyze`

export const UPLOAD_TRIGGER = `${API_BASE_URL}/file/upload`

export const DB_OPTIMISER_LIST = `${API_BASE_URL}/modules/DB Optimizer/tasks`

export const FETCH_API_CONFIG = `${API_BASE_URL}/configuration`

export const HYPERDRIVE_CONNECTION_TEST = `${API_BASE_URL}/connections/{connectionId}/test`

export const DATA_MOVER_LAMBDA = `${API_BASE_URL}/connections/{connectionId}/metadata`

export const DATA_MOVER_VOLUME_CALCULATOR = `${API_BASE_URL}/modules/Data Mover/migrate`

export const TASK_DATA_MOVER = `${API_BASE_URL}/modules/Data Mover/tasks`

export const TASK_DDL_CONVERTER = `${API_BASE_URL}/modules/DDL Converter/tasks`

export const TASK_ETL_CONVERTER = `${API_BASE_URL}/modules/ETL Converter/tasks`

export const RECOMMENDATION = `${API_BASE_URL}/modules/DB Optimizer/analyze`
