import {
    FETCH_APP_CONFIG,
    FETCH_APP_CONFIG_SUCCESS,
    FETCH_APP_CONFIG_FAILED
} from '../actions'

const INIT_STATE = {
    user: localStorage.getItem('user_id'),
    appConfig: {},
    fetchingData: false,
    activeDBs:[],
    activeETLs:[],
    failedMsg: ""
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_APP_CONFIG:
            return { ...state, fetchingData: true, failedMsg: '' }

        case FETCH_APP_CONFIG_SUCCESS:
            return { ...state, appConfig: action.payload, 
                activeDBs: action.payload?.app?.db_types?.filter(x=> x.is_active).map(x=>x.name),
                activeETLs: action.payload?.app?.etl_types?.filter(x=> x.is_active).map(x=>x.name),
                fetchingData: false, failedMsg: '' }
        case FETCH_APP_CONFIG_FAILED:
            return { ...state, appConfig: {}, fetchingData: false, failedMsg: action.payload }

        default: return { ...state }
    }
}
