import { all } from 'redux-saga/effects'
import authSagas from './auth/saga'
import  connectionSagas from './connections/saga'
import  dbOptimiserSagas from './dbOptimiser/saga'
import  appConfigSagas from './app-config/saga'
import  userSagas from './user/saga'

export default function * rootSaga (getState) {
  yield all([
    authSagas(),
    connectionSagas(),
    dbOptimiserSagas(),
    appConfigSagas(),
    userSagas()

  ])
}
