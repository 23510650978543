import { FETCH_CONNECTION_API,
  FETCH_CONNECTION_DETAILS,
  FETCH_CONNECTION_DETAILS_SUCCESS,
  FETCH_CONNECTION_API_SUCCESS,API_FAILED, FETCH_DELETE_CONNECTION } from "../actions";

export const fetchConnection = (user) => ({
    type: FETCH_CONNECTION_API,
    payload: { user }
})


export const fetchConnectionSuccess = (results) => ({
  type: FETCH_CONNECTION_API_SUCCESS,
  payload: results
})

export const failedRequest = (results) => ({
  type: API_FAILED,
  payload: results
})

export const deleteConnection = (payload) => ({
  type: FETCH_DELETE_CONNECTION,
  payload
})

export const getConnections = (payload) => ({
  type: FETCH_CONNECTION_DETAILS,
  payload
})

export const getConnectionsSuccess = (payload) => ({
  type: FETCH_CONNECTION_DETAILS_SUCCESS,
  payload
})