import {
  USER_LOGIN_FAILED, USER_LOGIN, USER_LOGIN_SUCCESS
} from '../actions'

export const userLogin = (user) => ({
  type: USER_LOGIN,
  payload: user
})

export const loginSuccess = (payload) => ({

  type: USER_LOGIN_SUCCESS,
  payload
})


export const loginFailed = (payload) => ({

  type: USER_LOGIN_FAILED,
  payload: payload
});
