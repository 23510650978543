import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_ERROR,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_ERROR,
    LOGOUT_USER,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_ERROR,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,
    USER_DETAILS,
    USER_DETAILS_FETCHING,
    FETCH_CONNECTION_API_SUCCESS,
    FETCH_CONNECTION_API,
    API_FAILED,
    FETCH_CONNECTION_DETAILS,
    FETCH_CONNECTION_DETAILS_SUCCESS,
    FETCH_DELETE_CONNECTION
} from '../actions'

const INIT_STATE = {
    user: localStorage.getItem('user_id'),
    connections: [],
    fetchingData: false,
    failedMsg: ""
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_CONNECTION_API:
            return { ...state, fetchingData: true, failedMsg: '' }

        case FETCH_CONNECTION_API_SUCCESS:
            return { ...state, connections: action.payload, fetchingData: false, failedMsg: '' }
        case FETCH_CONNECTION_DETAILS:
            return { ...state, fetchingData: true, failedMsg: '' }
        case FETCH_DELETE_CONNECTION:
            return { ...state, fetchingData: true, failedMsg: '' }


        case FETCH_CONNECTION_DETAILS_SUCCESS:
            return { ...state, fetchingData: true, failedMsg: '', selectedConnection: action.payload }
        case FETCH_CONNECTION_API_SUCCESS:
            return { ...state, connections: action.payload, fetchingData: false, failedMsg: '' }
        case API_FAILED:
            return { ...state, connections: [], fetchingData: false, failedMsg: action.payload }

        default: return { ...state }
    }
}
