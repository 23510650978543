/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE'

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR'
export const REGISTER_USER = 'REGISTER_USER'
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS'
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR'
export const LOGOUT_USER = 'LOGOUT_USER'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR'
export const USER_DETAILS = 'USER_DETAILS'
export const USER_DETAILS_FETCHING = 'USER_DETAILS_FETCHING'

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES'
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME'
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU'
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES'
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  'MENU_CHANGE_HAS_SUB_ITEM_STATUS'



// Connection screen actions 

export const FETCH_CONNECTION_API = "FETCH_CONNECTION_API"
export const FETCH_CONNECTION_API_SUCCESS = "FETCH_CONNECTION_API_SUCCESS"
export const FETCH_DELETE_CONNECTION = "FETCH_DELETE_CONNECTION"
export const API_FAILED = "API_FAILED"

//Db optmise Screen
export const FETCH_DB_OPTIMISER_TASKS_API = "FETCH_DB_OPTIMISER_TASKS_API"
export const FETCH_DB_OPTIMISER_TASKS_API_SUCCESS = "FETCH_DB_OPTIMISER_TASKS_API_SUCCESS"
export const FETCH_DB_OPTIMISER_TASKS_API_FAILED = "FETCH_DB_OPTIMISER_TASKS_API_FAILED"


//Db optmise Screen
export const FETCH_APP_CONFIG = "FETCH_APP_CONFIG"
export const FETCH_APP_CONFIG_SUCCESS = "FETCH_APP_CONFIG_SUCCESS"
export const FETCH_APP_CONFIG_FAILED = "FETCH_APP_CONFIG_FAILED"

export const FETCH_CONNECTION_DETAILS = "FETCH_CONNECTION_DETAILS"
export const FETCH_CONNECTION_DETAILS_SUCCESS = "FETCH_CONNECTION_DETAILS_SUCCESS"


export const USER_LOGIN = "USER_LOGIN"
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS"
export const USER_LOGIN_FAILED= "USER_LOGIN_FAILED"


export * from './menu/actions'
export * from './settings/actions'
export * from './auth/actions'
