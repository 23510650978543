import storage from 'redux-persist/lib/storage'

const initial = {
    fields: [],
    planName: ''
}

export const savePlanData = (data) => {
    return {
        type: 'STORE_EXECUTION_PLAN_DATA',
        payload: data
    }
};

export const clearPlanData = (data) => {
    return {
        type: 'CLEAN_EXECUTION_PLAN_DATA',
        payload: data
    } 
}


const storeExecutionPlanData = (state = initial, action) => {
    switch (action.type) {
        case 'STORE_EXECUTION_PLAN_DATA':
            return {
                saveExecutionPlan: action.payload
            }
        case 'CLEAN_EXECUTION_PLAN_DATA':
            storage.removeItem('persist:persistedScheduleStore')
            return {
                saveExecutionPlan: []
            }
        default: return { ...state }
    }
}

export default storeExecutionPlanData
