import { ADD_CONNECTION, DELETE_CONNECTION, GET_CONNECTION } from "../../constants/UrlConstants";
import { deleteUrl, getURL } from "../../constants/apiConstants";

export const loadConnection = async () => {
    return getURL(ADD_CONNECTION);
};

export const deleteConnection = async (id) => {
    let url = DELETE_CONNECTION.replace('{connectionId}', id)
    return deleteUrl(url);
};

export const getConnection = async (id) => {
    let url = GET_CONNECTION.replace('{connectionId}', id)
    return getURL(url);
};