import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import authUser from './auth/reducer'
import connectionReducer from './connections/reducer'
import optimiserReducer from './dbOptimiser/reducer'

import appConfigReducer from './app-config/reducer'

import globalLoadingSpinner from './globalLoadingSpinner'
import menu from './menu/reducer'
import navbar from './navbar'
import scheduleStore from './scheduleStore'
import settings from './settings/reducer'
import user from './user/reducer'

const persistConfig = {
  key: 'persistedScheduleStore',
  storage,
}
const persistedReducer = persistReducer(persistConfig, scheduleStore)

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  navbar,
  persistedReducer,
  connectionReducer,
  globalLoadingSpinner,
  optimiserReducer,
  appConfigReducer,
  user
})

export default reducers
